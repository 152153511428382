@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(55, 64, 69, 0.8);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(55, 64, 69);
}

.custom-scroll::-webkit-scrollbar-thumb {
    background: #374045;
    border-radius: 5px;
}